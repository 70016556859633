<template>
  <section class="wh-container">
    <header class="header">
      <p class="title">Cirurgias</p>
    </header>

    <Tab
      ref="surgeries_tabs"
      :index="currentTabIndex"
      :keys="tabs"
      @onChange="onChangeTab"
    >
      <PatientList v-if="tabs[currentTabIndex] === 'Lista de pacientes'" v-can="'CenCirurg2'" @change-tab="emitChangeTab"/>
      <FinishedSurgeries v-if="tabs[currentTabIndex] === 'Finalizados'" v-can="'CenCirurg3'" @change-tab="emitChangeTab"/>
    </Tab>
  </section> 
</template>

<script>
export default {
  name: 'Surgeries',

  metaInfo: {
    title: 'Eyecare - Centro cirúrgico'
  },

  model: {
    prop: 'currentIndex',
    event: 'onChange'
  },

  components: {
    Tab: () => import('@/components/Tab.vue'),
    PatientList: () => import('./Tabs/PatientList.vue'),
    FinishedSurgeries: () => import('./Tabs/FinishedSurgeries.vue'),
  },

  mounted() {
    this.onChangeTab(0, false)
    Object.keys(this.tabKeys).map(key => {
      this.tabs.push(this.tabKeys[key])
    })
  },

  data() {
    return {
      tabs: [],
      currentTabIndex: 0,
      tabKeys: {
        CenCirurg2: 'Lista de pacientes',
        CenCirurg3: 'Finalizados', 
      }
    }
  },

  methods: {
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/centro-cirurgico/${this.getPathByIndex(index)}`
        )
      }
    },

    getPathByIndex(index) {
      const indexes = {
        0: 'lista-pacientes',
        1: 'finalizados',
      }
      return indexes[index] || 'lista-pacientes'
    },

    emitChangeTab(data) {
      const { tab, index } = data
      this.$router.push({ params: { tab } })
      this.currentTabIndex = index
    },
  }
}
</script>

<style lang="scss">
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    margin-bottom: 24px;
  }
}
</style>